import React from "react";
import styled from "styled-components";
import HeroHeading from "../../elements/HeroHeading";

const StyledCenter = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const StyledContainer = styled.section`
  min-height: 30vh;
  display: block;
  text-align: center;
  color: white;
  padding: 10px;
  .slick-slide img {
    margin: auto;
  }
  img {
    height: 4rem;
    width: auto;
    filter: grayscale(100%);
    transition: filter 0.2s ease;
    @media (max-width: 767px) {
      height: 6rem;
    }
    &:hover {
      filter: grayscale(0%);
    }
  }
`;

const Logo = styled.img`
  margin: 20px 40px;
  cursor: pointer;
  @media (max-width: 767px) {
    margin: 1rem 2rem;
  }
  width: ${(props) => {
    if (props.name) {
      return "400px";
    } else {
      return "200px";
    }
  }};
`;

const LogoContainer = styled.div`
  width: 100%;
  padding: 0 4rem;
  @media (max-width: 767px) {
    padding: 0;
  }
`;

// const logos = ["gmc_logo","InterviewCake2","jb_beam","ph-logo-2","sketch","sticker-mule","streamyard"]

const CommunityPartnersLayout = () => {
  const communityPartners = [
    {
      name: "TIET MLSC",
      src: "/photos/thaparmlsc.png",
    },
    {
      name: "VIT OWASP",
      src: "/photos/vitowasp.png",
    },
    {
      name: "OSC VIT AP",
      src: "/photos/oscvit.png",
    },
    {
      name: "BackSlash TIET",
      src: "/photos/backslashtiet.png",
    },
    {
      name: "PyJaipur",
      src: "/photos/pyjaipur.png",
    },
    {
      name: "AWS UG Jaipur",
      src: "/photos/awsugjaipur.png",
    },
    {
      name: "ACM JUIT",
      src: "/photos/acmjuit.jpg",
    },
    {
      name: "Coding Club BITSP",
      src: "/photos/codingclub.jpg",
    },
  ];

  return (
    <StyledContainer id="sponsors">
      <StyledCenter>
        <HeroHeading heading={"Community Partners"} />
      </StyledCenter>
      <LogoContainer>
        {communityPartners.map((item) => (
          <Logo src={item.src} alt={item.name} title={item.name} />
        ))}
      </LogoContainer>

      <div style={{ margin: "10px" }}></div>

      <div style={{ margin: "10px" }}></div>
    </StyledContainer>
  );
};

export default CommunityPartnersLayout;
