import React from 'react'
import styled from "styled-components";
import { motion } from "framer-motion";
import { useMediaQuery } from 'react-responsive';

const BubbleContainer = styled.div`
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	@keyframes floating {
		0%{
			transform: translate(0,  0px);
		}
		50%{
			transform: translate(0, 40px);
		}
		100%{
			transform: translate(0, -0px);
		}
	}
	@keyframes increasing {
		0% {
			transform: scale(0.9);
		}
		100% {
			transform: scale(1.5);
		}
	}
	}
`

const Bubbles = styled.div`
	position: absolute;
	border-radius: 50%;
	z-index: -1;
	opacity: 30%;
	filter:blur(${props=>(props.blur)?props.blur:"0px"});
	width: ${props => props.width};
	height: ${props => props.height};
	background: ${props=>props.color};
	top: ${props => (props.top) ? props.top : `auto`};
	left: ${props => (props.left) ? props.left : `auto`};
	bottom: ${props => (props.bottom) ? props.bottom : `auto`};
	right: ${props => (props.right) ? props.right : `auto`};
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	animation: ${props=>props.animation};
`

const Triangles = styled.div`
	position: absolute;
	opacity: 30%;
	filter: blur(${props=>props.blur});
	width: ${props => props.width};
	top: ${props => (props.top) ? props.top : `auto`};
	left: ${props => (props.left) ? props.left : `auto`};
	bottom: ${props => (props.bottom) ? props.bottom : `auto`};
	right: ${props => (props.right) ? props.right : `auto`};
	border-top: 0;
	border-bottom: ${props => props.size}px solid ${props=>props.color};
	border-left: ${props => props.size*3/5}px solid rgba(255, 255, 255, 0);
	border-right: ${props => props.size * 3 / 5}px  solid rgba(255, 255, 255, 0);
`


const BubblesLayout = () => {
	const TriangleMotion = motion.custom(Triangles);
	const BubblesMotion = motion.custom(Bubbles);
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)'
	})

	return isDesktopOrLaptop && (
		<BubbleContainer>
			{isDesktopOrLaptop && <>
			<Bubbles
				top="40%"
				left="5vw"
				color="#00c99c"
				width="10px"
				height="10px"
				blur="1px"
				animation="floating 3s ease-in-out infinite"
			/>
			<Bubbles
				top="60%"
				right="10vw"
				color="#49a1fc"
				width="30px"
				height="30px"
				animation="increasing 2s infinite alternate"
			/>
			<Bubbles
				top="70%"
				right="20vw"
				color="#ff3cf0"
				width="10px"
				height="10px"
				blur="1px"
				animation="floating 3s ease-in-out infinite"
			/>
			<TriangleMotion
				top="30%"
				right="12vw"
				size="30"
				color="#666699"
				animate={{ rotate: 60, y: [10, 0, 10], x:0}}
				transition={{
					yoyo: Infinity,
					type: "tween",
					duration: 2,
				}}
				blur="1.5px"
			/>
			<TriangleMotion
				bottom="20%"
				left="30vw"
				size="50"
				color="#009c05"
				blur="3px"
				animate={{ rotate: 135, y: [10, 0, 10], x:0 }}
				transition={{
					yoyo: Infinity,
					type: "tween",
					duration: 2,
				}}
			/>
			<BubblesMotion
				top="50%"
				left="15vw"
				color="#fca249"
				width="30px"
				height="30px"
				blur="0.1px"
				animate={{
					scale: [1, 2, 2, 1, 1],
					rotate: [0, 0, 270, 270, 0],
					borderRadius: ["20%", "20%", "50%", "50%", "20%"]
				}}
				transition={{
					duration: 3,
					ease: "easeInOut",
					times: [0, 0.2, 0.5, 0.8, 1],
					loop: Infinity,
					repeatDelay: 1
				}}
			/>
			</>}
		</BubbleContainer>
	)
}

export default BubblesLayout
