import React from "react"
import HeroLayout from "./components/HeroLayout";
import RulesLayout from "./components/RulesLayout";
import TimelineLayout from "./components/TimelineLayout";
import NavComponents from "./components/NavComponents";
import PrizesLayout from "./components/PrizesLayout";
import TeamLayout from "./components/TeamLayout";
import FooterLayout from "./components/FooterLayout";
import SponsorLayout from "./components/SponsorLayout";
import CommunityPartnersLayout from "./components/CommunityPartnersLayout";

const App = () => {
    return (
		<div>
			<NavComponents />
			<HeroLayout />
			<RulesLayout />
			<TimelineLayout />
			<PrizesLayout />
			<SponsorLayout />
			<CommunityPartnersLayout />
			<TeamLayout />
			<FooterLayout />
		</div>
	)
}

export default App;