import React from 'react';
import Card from 'react-bootstrap/Card';
import styled from "styled-components";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import HeroHeading from '../../elements/HeroHeading';

const StyledCenter = styled.div`
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
`

const StyledContainer = styled.section`
	min-height: 100vh;
	display:block;
	text-align: center;
	color:white;
	padding: 40px;

	.center{
		display: flex;
		justify-content: center;
		align-items: center;
	}
`

const StyledCard = styled(Card)`
	color: black;
	width: 364px;
	top:5px;
	right:-5px;
	display: flex;
	position:relative;
	align-items: center;
	margin:0.5em 1em 3em 1em;
	transition: all 0.2s ease;
	&:hover{
		top:0px;
		right:0px;
		box-shadow: 5px 5px 0px 0px var(--accent-primary);
	}
`

const StyledCardTitle = styled(Card.Title)`
	font-weight:bold;
`

const IconHolder = styled.div`
	display:flex;
	font-size:1.6em;
	justify-content:center;
`

const Icon = styled.i`
	margin: auto 1rem;
	cursor: pointer;
	color: #333333;
`

const SubHeading = styled.h2`
	padding: 3vh 0;
	opacity:0.8;
`

const StyledCardImage = styled(Card.Img)`
	height: 20rem;
	object-fit: cover;
	object-position: top;
`

const StyledRow = styled(Row)`
	padding: 2rem 8rem 0 8rem;
	@media (max-width: 767px) {
		padding: 0.5rem;
	}
`

const TeamLayout = () => {
	const teamsInfo = [
		{
			heading: "Convenors",
			members: [
				{
					id: 1,
					name: "Arijit Roy",
					imageSrc:
						"https://res.cloudinary.com/randomize/image/upload/q_40/v1637522646/signed_upload_demo_uw/ub41yo3dwfeyukao1enh.jpg",
					description: "President",
					github: "https://github.com/radioactive11",
					website: "radioactive11.codes",
				},
				{
					id: 2,
					name: "Nitigya Kargeti",
					imageSrc:
						"https://res.cloudinary.com/randomize/image/upload/q_40/v1637523308/signed_upload_demo_uw/dauzcmdnsh9t7olddibg.jpg",
					description: "Vice President",
					github: "https://github.com/Neat-Stack",
					linkedin: "https://www.linkedin.com/in/nitigyak",
				},
				{
					id: 3,
					name: "Yashwin Saini",
					imageSrc:
						"https://res.cloudinary.com/randomize/image/upload/q_40/v1637523512/signed_upload_demo_uw/f06tujqffycggen67919.jpg",
					description: "Managing Director",
					linkedin: "https://www.linkedin.com/in/yashwin-saini-961304158",
					instagram: "https://instagram.com/yashwin_saini?utm_medium=copy_link",
				},
			]
		},
		{
			heading:"Co-Convenors",
			members:[
				{
					id: 4,
					name: "Aditya Yadav",
					imageSrc:
						"https://res.cloudinary.com/randomize/image/upload/q_40/v1637523506/signed_upload_demo_uw/q3xsb5igafdxsvyailmq.jpg",
					description: "General Secretary",
					linkedin: "https://www.linkedin.com/in/aditya-yadav-49929b190",
					github: "https://github.com/aditya635",
				},
				{
					id: 5,
					name: "Mudit Shukla",
					imageSrc:
						"https://res.cloudinary.com/randomize/image/upload/q_40/v1637523500/signed_upload_demo_uw/fbrnj6bukkwak9drblm5.jpg",
					description: "Treasurer",
					linkedin: "https://www.linkedin.com/in/muditshukla123",
					github: "https://github.com/mudits02",
				},
				{
					id:10,
					imageSrc: "https://res.cloudinary.com/randomize/image/upload/q_40/v1637523474/signed_upload_demo_uw/c3sjay1a1dkfbgz33xoi.jpg",
					name: "Sneegdh Krishna",
					github: "https://github.com/krishnova",
					linkedin: "https://www.linkedin.com/in/sneegdhk/",
				}
			]
		},
		{
			heading:"Tech Team",
			members:[
				{
					id: 6,
					name: "Kartik Goel",
					imageSrc:
						"https://res.cloudinary.com/randomize/image/upload/q_40/v1637523499/signed_upload_demo_uw/f6mlbtfctq91tv1wuxi4.jpg",
					description: "Webmaster",
					github: "https://github.com/kg-kartik",
					linkedin: "https://www.linkedin.com/in/kg-kartik/",
				},
				{
					imageSrc:"https://res.cloudinary.com/randomize/image/upload/q_40/v1637523445/signed_upload_demo_uw/xtirrbti5qinmspqxlm8.jpg",
					name: "Boidushya Bhattacharya",
					github: "https://github.com/boidushya",
					linkedin: "https://www.linkedin.com/in/boidushya-bhattacharya-6b31a779/",
				},
				{
					imageSrc:"https://res.cloudinary.com/kartik321/image/upload/v1643867198/fbhzcdkezcwbijte82zf.png",
					name: "Siddhant Kumar Singh",
					github: "https://github.com/geralt-of-rivia-witcher",
					linkedin: "https://www.linkedin.com/in/siddhant-kumar-singh-/",
				}
			]
		},
		{
			heading: "Outreach Team",
			members: [
				{
					imageSrc:
						"https://res.cloudinary.com/kartik321/image/upload/v1643799550/rsm3b7lffsxbmr5082ji.jpg",
					description: "Head of Publicity",
					name: "Aniket Verma",
					github: "https://github.com/Aniket-x-code",
					linkedin: "https://github.com/aniket-verma-b305211b9",
				},
				{
					id: 8,
					name: "Dinisha Agarwal",
					imageSrc:
						"https://res.cloudinary.com/kartik321/image/upload/v1643918770/m9sded0qsd7yhs09eu4t.jpg",
					github: "https://github.com/Dinisha18",
					linkedin: "https://www.linkedin.com/in/dinisha-agarwal-58472220a/",
				},
				{
					id: 6,
					name: "Sanket Deb",
					imageSrc:
						"https://res.cloudinary.com/kartik321/image/upload/v1643799553/aq3ymj1ezcw1fikcloxa.jpg",
					github: "https://github.com/Sanket-deb",
					linkedin: "https://www.linkedin.com/in/sanket-deb-7a7669196/",
				},
				{
					id:9,
					imageSrc: "https://res.cloudinary.com/randomize/image/upload/q_40/v1637523468/signed_upload_demo_uw/sc7djxziw3xrpfd0dq7y.png",
					name: "Subhankar Sen",
					github: "https://github.com/subhankar01",
					linkedin: "https://www.linkedin.com/in/subhankar-sen-a62457190",
				},
				{
					id:11,
					imageSrc: "https://res.cloudinary.com/randomize/image/upload/v1637523431/signed_upload_demo_uw/dd4mijtpuu8n3d5kh0gk.jpg",
					name: "Aashrit Garg",
					github: "https://github.com/Aashrit-Garg",
					linkedin: "https://www.linkedin.com/in/aashrit-garg-b9846b146/",
				},
				{
					id:11,
					imageSrc: "https://res.cloudinary.com/kartik321/image/upload/v1644039933/hhnd2edtqtvk7fmsb4sl.jpg",
					name: "Chaaritra Jain",
					github: "https://github.com/nightmare021",
					linkedin: "https://www.linkedin.com/in/chaaritra-jain-551b70151",
				},
			],
		},
		{
			heading:"Operations Team",
			members:[
				{
					imageSrc:"https://res.cloudinary.com/kartik321/image/upload/v1643799549/hgzr4socxjcexnlecxto.jpg",
					name: "Ishan Wadhwani",
					github: "https://github.com/ishanwadhwani",
					linkedin: "https://www.linkedin.com/in/ishan-wadhwani-16a5b921b",
				},
				{
					imageSrc:
						"https://res.cloudinary.com/kartik321/image/upload/v1643799550/rsm3b7lffsxbmr5082ji.jpg",
					description: "Head of Publicity",
					name: "Aniket Verma",
					github: "https://github.com/Aniket-x-code",
					linkedin: "https://linkedin.com/in/aniket-verma-b305211b9",
				},
				{
					imageSrc:
						"https://res.cloudinary.com/randomize/image/upload/v1637523436/signed_upload_demo_uw/dtyqcms6kayzcmfnlzbr.jpg",
					description: "Head of Logistics",
					name: "Esha Baweja",
					github: "https://github.com/eshabaweja",
					linkedin: "https://www.linkedin.com/in/esha-baweja-2436591b8/",
				},
				{
					id: 6,
					name: "Sanket Deb",
					imageSrc:
						"https://res.cloudinary.com/kartik321/image/upload/v1643799553/aq3ymj1ezcw1fikcloxa.jpg",
					github: "https://github.com/Sanket-deb",
					linkedin: "https://www.linkedin.com/in/sanket-deb-7a7669196/",
				},
				{
					id: 9,
					name: "Revanur Srinivas Sidhartha Sarma",
					imageSrc:
						"https://res.cloudinary.com/kartik321/image/upload/v1643799549/rc5mahwimcn0zvma2ji0.jpg",
					github: "https://github.com/pointbreak09",
					linkedin: "https://www.linkedin.com/in/srinivas-sidhartha-sarma-revanur-657415120",
				},
				{
					id:11,
					imageSrc: "https://res.cloudinary.com/randomize/image/upload/v1637523431/signed_upload_demo_uw/dd4mijtpuu8n3d5kh0gk.jpg",
					name: "Aashrit Garg",
					github: "https://github.com/Aashrit-Garg",
					linkedin: "https://www.linkedin.com/in/aashrit-garg-b9846b146/",
				}
			],
		},
		{
			heading: "Design Team",
			members: [
				{
					imageSrc:"https://res.cloudinary.com/kartik321/image/upload/v1643799549/hgzr4socxjcexnlecxto.jpg",
					name: "Ishan Wadhwani",
					github: "https://github.com/ishanwadhwani",
					linkedin: "https://www.linkedin.com/in/ishan-wadhwani-16a5b921b/",
				},
				{
					imageSrc:
						"https://res.cloudinary.com/randomize/image/upload/v1637523436/signed_upload_demo_uw/dtyqcms6kayzcmfnlzbr.jpg",
					description: "Head of Logistics",
					name: "Esha Baweja",
					github: "https://github.com/eshabaweja",
					linkedin: "https://www.linkedin.com/in/esha-baweja-2436591b8/",
				},
				{
					imageSrc:
						"https://res.cloudinary.com/randomize/image/upload/q_40/v1637523451/signed_upload_demo_uw/uccvkdxempqzx85uiflf.jpg",
					name: "Aditya Agarwal",
					github: "https://github.com/aditya33agrawal",
					linkedin: "https://www.linkedin.com/in/aditya-agrawal-ba6b64200",
				},
				{
					imageSrc:
						"https://res.cloudinary.com/randomize/image/upload/q_40/v1637523484/signed_upload_demo_uw/nefwhyyi2g5vt9soti13.jpg",
					description: "Head of Videography",
					name: "Sameed Ul Haq",
					github: "https://github.com/",
					linkedin: "https://www.linkedin.com/",
				},
				{
					id: 6,
					name: "Sanket Deb",
					imageSrc:
						"https://res.cloudinary.com/kartik321/image/upload/v1643799553/aq3ymj1ezcw1fikcloxa.jpg",
					github: "https://github.com/Sanket-deb",
					linkedin: "https://www.linkedin.com/in/sanket-deb-7a7669196/",
				},
				{
					imageSrc:"https://res.cloudinary.com/kartik321/image/upload/v1643799562/cviqfbw4sdhyjdvq6zom.jpg",
					name: "Jahnavi Awasthi",
					github: "https://github.com/AwasthiJahnavi",
					linkedin: "https://www.linkedin.com/in/jahnavi-awasthi-b57044214/",
				},
				{
					id: 9,
					name: "Revanur Srinivas Sidhartha Sarma",
					imageSrc:
						"https://res.cloudinary.com/kartik321/image/upload/v1643799549/rc5mahwimcn0zvma2ji0.jpg",
					github: "https://github.com/pointbreak09",
					linkedin: "https://www.linkedin.com/in/srinivas-sidhartha-sarma-revanur-657415120",
				}
			]
		}

	]
	return (
		<StyledContainer id="team">
			<StyledCenter>
				<HeroHeading heading={"Team"} />
			</StyledCenter>
			<>
				{teamsInfo.map((info) => {
					return (
						<>
							<SubHeading>{info.heading}</SubHeading>
							<StyledRow md={5}>
								{info.members.map((item) => {
									return (
										<Col md={4} className="center">
											<StyledCard>
												<StyledCardImage src={item.imageSrc} />
												<Card.Body>
													<StyledCardTitle>{item.name}</StyledCardTitle>
													<Card.Text>
														{item.text}
													</Card.Text>
													<IconHolder>
														<a href={(item.hasOwnProperty("github")) ? item.github : null}><Icon className="fab fa-github" ></Icon></a>
														<a href={(item.hasOwnProperty("linkedin")) ? item.linkedin : null}><Icon className="fab fa-linkedin-in"></Icon></a>
													</IconHolder>
												</Card.Body>
											</StyledCard>
										</Col>
									)
								})
								}
							</StyledRow>
						</>
					)
				})}
			</>
		</StyledContainer>
	)
}

export default TeamLayout
