import React from 'react'
import styled from "styled-components";

const CardWrapper = styled.a`
	display: flex;
	align-items: center;
	margin: 2rem;
	&:hover{
		text-decoration: none;
	}
	@media only screen and (max-width: 767px) {
		margin: 2rem 1.5rem;
	}
`

const CardContainer = styled.div`
	display: flex;
	flex-direction:column;
	background: white;
	color: black;
	border-radius: 3rem;
	cursor: pointer;
	width: 350px;
	top:5px;
	right:-5px;
	position:relative;
	transition: all 0.2s ease;
	&:hover{
		top:0px;
		right:0px;
		box-shadow: 5px 5px 0px 0px var(--accent-primary);
	}
	@media only screen and (max-width: 767px) {
		width: 90vw;
	}
	`

const ImgContainer = styled.div`
	display:flex;
	align-items:center;
	justify-content:center;
	min-height: 22rem;
	@media only screen and (max-width: 767px) {
		min-height: 8rem;
	}
`


const Img = styled.img`
	color: #333333;
	max-width:80%;
	max-height:80%;
	object-fit:contain;
`

const TextContainer = styled.div`
	margin: 1rem;
	font-weight: 600;
	font-style: italic;
	font-size: 1rem;
	white-space: initial;
	@media only screen and (max-width: 767px) {
		font-size: 1rem;
	}
`

const Heading = styled.h1`
	font-weight:700;
	margin: 2rem;
	font-size: 1.6rem;
	@media only screen and (max-width: 767px) {
		font-size: 1.4rem;
	}
`


const index = (props) => {
	return (
		<>
			<CardWrapper href={props.link}>
				<CardContainer>
					<Heading>
						{props.title}
					</Heading>
						<ImgContainer>
							<Img src={props.img} alt="" />
						</ImgContainer>
						<TextContainer>{props.text}</TextContainer>
				</CardContainer>
			</CardWrapper>
		</>
	)
}

export default index
