import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import BubblesLayout from "../BubblesLayout";
import styled from "styled-components";
import "../../styles/glitch.scss";

const StyledSection = styled.section`
  min-height: 100vh;
  a,
  a:hover {
    text-decoration: none;
  }
  .button {
    outline: none;
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 1em 2.5em;
    text-decoration: none !important;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    @media (max-width: 768px) {
      padding: 1em 2em;
      display: inline-block;
    }
  }
  .fourth {
    border: solid 0.15rem #5865f7;
    color: #fff;
    background-image: linear-gradient(45deg, #5865f7 50%, transparent 50%);
    background-position: 100% 0;
    background-size: 400%;
    transition: background 300ms ease-in-out, box-shadow ease-in-out 300ms;
    &:hover {
      background-position: 0%;
      box-shadow: inset 0 0 0 2px #5865f7;
    }
  }
`;
const StyledHero = styled(Jumbotron)`
  text-align: center;
  min-height: 85vh;
  display: flex;
  align-items: center;
  background-color: transparent !important;

  .title {
    position: relative;
    color: white;
    font-size: 8rem;
  }
`;

const StyledPara = styled.p`
  color: white;
  flex: 0 1 100%;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  font-size: ${(props) => props.size};
  @media only screen and (min-width: 1224px) {
    max-width: 40vw;
  }
`;

const ButtonContainer = styled.div`
  flex: 0 1 100%;
  margin-top: 6rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

const StyledButton = styled.button`
  /* padding: 0.5rem 3rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  height: 44px;
  width: 312px;
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Heading = styled.h4`
  position: relative;
  color: white;
  font-size: 7vw;
  @media only screen and (max-width: 767px) {
    font-size: 4rem;
  }
`;

// const DashboardButton = styled.button`
//   background-color: #233136;
//   outline: none;
//   box-sizing: border-box;
//   appearance: none;
//   border-radius: 0.6em;
//   cursor: pointer;
//   border-color: transparent;
//   font-size: 1.1rem;
//   font-weight: 700;
//   padding: 1em 1.3em;
//   text-decoration: none;
//   text-align: center;
//   text-transform: uppercase;
//   font-family: "Montserrat", sans-serif;
//   margin-left: 50px;
//   @media (max-width: 768px) {
//     padding: 1em 1.3em;
//     margin-left: 0px;
//     margin-top: 20px;
//   }
//   &:hover {
//     background-color: #1f78d1;
//   }
// `;

const Icon = styled.i`
  margin-right: 10px;
`;

const HeroLayout = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apply.devfolio.co/v2/sdk.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <StyledSection id="home">
      <BubblesLayout />
      <StyledHero>
        <StyledContainer>
          <Heading className="my-3">Renaissance</Heading>
          <StyledPara size={"30px"}>HACK | INNOVATE | CREATE</StyledPara>
          <ButtonContainer>
            <div
              class="apply-button"
              data-hackathon-slug="renaissance1"
              data-button-theme="dark"
              style={{ height: "44px", width: "312px" }}
            ></div>
            <a
              href="https://discord.gg/vsV6xxgb"
              target="_blank"
              rel="noreferrer"
            >
              <StyledButton className="button fourth">
                <Icon className="fab fa-discord"></Icon>Join Discord
              </StyledButton>
            </a>
          </ButtonContainer>
        </StyledContainer>
      </StyledHero>
    </StyledSection>
  );
};

export default HeroLayout;
