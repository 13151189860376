import React from "react";
import styled from "styled-components";

const Section = styled.section`
    min-height: 10vh;
    background: #1b1b1b;
    padding-bottom: 5rem;
    margin-top:5vh;
    @media (max-width: 767px) {
        padding-bottom: 10px;
    }
`;

const BrandHeader = styled.h1`
    text-align: left;
    font-size: 2em;
    font-weight: bold;
	padding: 4rem 0;
	margin-left: 5rem;
    @media (max-width: 767px) {
        text-align: center;
        padding: 2.5rem;
		margin-left: 0;
    }
    a {
        &:hover {
            color: white;
            text-decoration: none;
        }
    }
`;

const LogoContainer = styled.div`
    margin: auto;
    @media (max-width: 767px) {
        width: 80%;
    }
`;

const LogoLink = styled.a`
    text-shadow: none;
    color: white;
    top: 4px;
    font-size: 36px;
    right: -4px;
    transition: all 0.2s ease;
    position: relative;
    &:hover {
        top: 0px;
        right: 0px;
        text-shadow: 4px 4px var(--accent-primary);
    }
`;

const Container = styled.div`
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
	justify-content: space-between;
    color: white;
    @media (max-width: 767px) {
        justify-content: center;
    }
`;

const SubContainer = styled.div`
    font-family: "Roboto Mono", monospace;
    fontsize: 16px;
    @media (max-width: 767px) {
        flex: 1 0 100%;
        margin-bottom: 30px;
    }
`;

const IconWrapper = styled.div`
	display: flex;
`

const MiddleContainer = styled.div`
    font-family: "Roboto Mono", monospace;
	a{
		margin-right: 1rem;
	}
    @media (max-width: 767px) {
        flex: 1 0 100%;
        margin-bottom: 30px;
        padding-left: 0;
    }
`;

const Heading = styled.h4`
	margin: 0 !important;
	padding-bottom: 25px;
    font-size: 18px;
    font-family: "Overpass", sans-serif;
    font-weight: bold;
    letter-spacing: 0.1rem;
	width: fit-content;
    @media (max-width: 767px) {
        padding-bottom: 20px;
    }
`;

const Content = styled.p`
    margin-bottom: 10px;
`;

const Link = styled.a`
    color: white;
    &:hover {
        color: white;
    }
`;

const IconContainer = styled.a`
    font-size: 2rem;
    color:white;
`;

const Icon = styled.i`
    margin: auto 1rem;
    cursor: pointer;
    color: #909090;
    transition: all 0.2s ease;
    &:hover {
        color: white;
    }
`;

const LinksContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: 767px) {
        justify-content: flex-start;
    }
`

const index = () => {
    const linkList = [
        {
            name: "Home",
            href: "#home",
        },
        {
            name: "About",
            href: "#about",
        },
        {
            name: "Website",
            href: "https://randomizemuj.com",
        },
    ];
    return (
        <Section id="contact">
			<LogoContainer>
				<BrandHeader>
					<LogoLink href="/">Randomize</LogoLink>
				</BrandHeader>
			</LogoContainer>
            <Container>
                <SubContainer>
                    <Heading>CONTACT US</Heading>
                    <Content>Arijit Roy: arijit@gmail.com</Content>
                    <Content>Nitigya Kargeti: nitigya@gmail.com</Content>
                </SubContainer>
				<MiddleContainer>
					<Heading>QUICK LINKS</Heading>
					<LinksContainer>
						{linkList.map((obj, i) => (
							<Link href={obj.href}>
								<Content key={i}>{obj.name}</Content>
							</Link>
						))}
					</LinksContainer>
				</MiddleContainer>
				<SubContainer>
                    <Heading>CONNECT WITH US</Heading>
					<IconWrapper>
						<IconContainer target="_blank" href="https://www.instagram.com/randomizemuj/">
							<Icon className="fab fa-instagram" />
						</IconContainer>
						<IconContainer target="_blank" href="https://www.facebook.com/RandomizeMUJ">
							<Icon className="fab fa-facebook" />
						</IconContainer>
						<IconContainer target="_blank" href="https://www.linkedin.com/company/randomizemuj/mycompany/">
							<Icon className="fab fa-linkedin-in" />
						</IconContainer>
					</IconWrapper>
				</SubContainer>
            </Container>
        </Section>
    );
};

export default index;
