import React from 'react';
import styled from "styled-components";
import VerticalCollectionCards from "../../elements/VerticalCollectionCards";
import Slider from "react-slick";
import { useMediaQuery } from 'react-responsive';
import HeroHeading from '../../elements/HeroHeading';

const StyledCenter = styled.div`
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
`

const StyledContainer = styled.section`
	min-height: 50vh;
	display:block;
	text-align: center;
	color:white;
	padding: 40px;
`

const StyledSlider = styled(Slider)`
	max-width:75%;
	max-height:75%;
	margin:6rem auto;
	@media only screen and (max-width: 767px) {
		max-width:100%;
		max-height:100%;
	}
`

const FlexContainer = styled.div`
	display: flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
`

const PrizesLayout = () => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1200px)' });
	const prizes = [
		{
			title: "Winner",
			img: "/photos/winner.png",
			text: "₹15K",
			link: "link"
		},
		{
			title: "Runner Up",
			img: "/photos/second.png",
			text: "₹10K",
		},
		{
			title: "2nd Runner Up",
			img: "/photos/third.png",
			text: "₹5K",
		},
		{
			title: "Randomize Team",
			img: "/photos/randomize.png",
			text: "₹3K",
		},
		{
			title: "Best Idea",
			img: "/photos/bestidea.jpg",
			text: "₹3K",
		}

	]
	const settings = {
		slidesToShow: prizes.length - 2,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 1500,
		arrows: false,
		dots: false,
		cssEase: "cubic-bezier(0.68, -0.55, 0.27, 1.55)",
		pauseOnHover: true,
		responsive: [
			{
				breakpoint: 650,
				settings: {
					slidesToShow: 1
				}
			}
		]
	};
	return (
		<StyledContainer id="prizes">
			<StyledCenter>
				<HeroHeading heading={"Prizes"} />
			</StyledCenter>
			<FlexContainer>
				{
					(isTabletOrMobile) ? (
						<StyledSlider {...settings}>
							{
								prizes.map((item,i) => (
									<VerticalCollectionCards key={i} {...item} />
								))
							}
						</StyledSlider>
					)
						: (
							<>
								<StyledSlider {...settings}>
									{
										prizes.map((item,i) => (
											<VerticalCollectionCards key={i} {...item} />
										))
									}
								</StyledSlider>
							</>
						)
				}
			</FlexContainer>
		</StyledContainer>
	)
}

export default PrizesLayout;