import React from 'react';
import styled from "styled-components";

const Header = styled.h1`
	margin: 3rem auto;
	font-size: 4rem;
	position: relative;
	@media only screen and (max-width: 767px) {
		font-size: 2rem;
		font-weight: 700;
	}
`

const StyledBorder = styled.div`
	content: "";
	background: var(--accent-primary);
	position: absolute;
	left: 0;
	width: 60%;
	height: 0.5rem;
	border-radius: 10px;
`

const HeroHeading = ({ heading }) => {
	return (
		<Header>
			{`${heading} :`}
			<StyledBorder />
		</Header>
	)
}

export default HeroHeading;
