import React from "react";
import styled from "styled-components";
import HeroHeading from "../../elements/HeroHeading";

const StyledCenter = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const StyledContainer = styled.section`
  min-height: 100vh;
  display: block;
  text-align: center;
  color: white;
  padding: 10px;
  @media (max-width: 767px) {
    padding: 0;
  }
  .slick-slide img {
    margin: auto;
  }
  img {
    height: 4rem;
    width: auto;
    filter: grayscale(100%);
    transition: filter 0.2s ease;
    @media (max-width: 767px) {
      height: 4rem;
    }
    &:hover {
      filter: grayscale(0%);
    }
  }

  .button {
    outline: none;
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border-radius: 0.6em;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    padding: 1.2em 2.8em;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
  }

  .fourth {
    border: solid 0.15rem var(--accent-primary);
    color: #fff;
    background-image: linear-gradient(
      45deg,
      var(--accent-primary) 50%,
      transparent 50%
    );
    background-position: 100%;
    background-size: 400%;
    transition: background 300ms ease-in-out;
    &:hover {
      background-position: 0;
    }
  }
`;

const Logo = styled.img`
	margin:20px 40px;
	cursor: pointer;
	@media (max-width: 767px) {
        margin: 1rem 2rem;
    }
	width:400px;
};
`;

const LogoContainer = styled.div`
  width: 100%;
  padding: 0 4rem;
  @media (max-width: 767px) {
    padding: 0;
  }
`;

const ButtonContainer = styled.div`
  flex: 0 1 100%;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
`;

const StyledButton = styled.button`
  padding: 0.5rem 3rem;
  outline: none;
`;

// const logos = ["gmc_logo","InterviewCake2","jb_beam","ph-logo-2","sketch","sticker-mule","streamyard"]

const SponsorLayout = () => {
  const sponsors = [
    {
      name: "Devfolio",
      src: "/photos/Devfolio.png",
      href: "https://devfolio.co/",
    },
    {
      name: "Polygon",
      src: "/photos/polygon.png",
      href: "https://polygon.technology/",
    },
    {
      name: "Filecoin",
      src: "/photos/Filecoin.svg",
      href: "https://filecoin.io/",
    },
    {
      name: "Tezos",
      src: "/photos/tezos.svg",
      href: "https://tezos.com/",
    },
    {
      name: "Celo",
      src: "/photos/celo.svg",
      href: "https://tezos.com/",
    },
    {
      name: "Nervos",
      src: "/photos/nervos.png",
      href: "https://www.nervos.org/",
    },
    {
      name: "GirlScript",
      src: "/photos/girlscript.jpeg",
      href: "https://www.girlscript.tech/home",
    },
    {
      name: "Skill Vertex",
      src: "/photos/skillvertex.png",
      href: "https://skillvertex.in/",
    },
    {
      name: "Techvanto Academy",
      src: "/photos/techvanto.png",
      href: "https://techvantoacademy.com",
    },
    {
      name: "Product Hunt",
      src: "/photos/ph-logo-2.png",
      href: "https://www.producthunt.com/",
    },
    {
      name: "Jet Brains",
      src: "/photos/jb_beam.png",
      href: "https://www.jetbrains.com/",
    },
    {
      name: "Rezonance",
      src: "/photos/rezonance.png",
      href: "https://www.rezonanceindia.tech/",
    },
    {
      name: "Sketch",
      src: "/photos/sketch.png",
      href: "https://www.sketch.com/",
    },
    {
      name: "BlueLearn",
      src: "/photos/bluelearn.png",
      href: "https://www.bluelearn.in/",
    },
    {
      name: "Sticker Mule",
      src: "/photos/sticker-mule.png",
      href: "https://www.stickermule.com/en-in",
    },
    {
      name: "MLH",
      src: "/photos/mlh.jpg",
      href: "https://mlh.io/",
    },
    {
      name: "Replit",
      src: "/photos/replit.png",
      href: "https://replit.com",
    },
    {
      name: "Streamyard",
      src: "/photos/streamyard.png",
      href: "https://streamyard.com/",
    },
    {
      name: "Geeks for Geeks",
      src: "/photos/gfg-new-logo.png",
      href: "https://www.geeksforgeeks.org/",
    },
    {
      name: ".XYZ",
      src: "/photos/xyz-logo-color.png",
      href: "https://gen.xyz/",
    },
    {
      name: "Give My Certificate",
      src: "/photos/gmc_logo.png",
      href: "https://givemycertificate.com/",
    },
    {
      name: "Interview Cake",
      src: "/photos/InterviewCake 2.png",
      href: "https://www.interviewcake.com/",
    },
    {
      name: "StickerYou",
      src: "https://cdn.agilitycms.com/stickeryou/GeneralImages/sylogo_4x3.png",
      href: "https://www.stickeryou.com/",
    },
    {
      name: "Balasmiq Cloud",
      src: "/photos/balasmiqcloud.png",
      href: "https://balsamiq.com/",
    },
    {
      name: "Rosenfeld",
      src: "/photos/rosenfeldlogo.svg",
      href: "https://rosenfeldmedia.com/",
    },
  ];

  return (
    <StyledContainer id="sponsors">
      <StyledCenter>
        <HeroHeading heading={"Sponsors"} />
      </StyledCenter>
      <LogoContainer>
        {sponsors.map((item) => (
          <a target="_blank" rel="noreferrer" href={item.href}>
            <Logo src={item.src} alt={item.name} title={item.name} />
          </a>
        ))}
      </LogoContainer>

      <ButtonContainer>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://docs.google.com/forms/d/e/1FAIpQLSdfaxtT19HCUpm0Ir_ZOAkNPq_dTgqjL8Ey-4PuLoC-IbKU_w/viewform?usp=send_form"
        >
          <StyledButton className="button fourth">Sponsor Us</StyledButton>
        </a>
      </ButtonContainer>
    </StyledContainer>
  );
};

export default SponsorLayout;
