import React from "react"
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import styled from "styled-components";
import logo from "../../assets/rose-favicon.svg";

const NavContainer = styled.div`
.navbar-dark .navbar-toggler {
    border-color: rgb(255 255 255);
    outline: none;
}
.navbar {
	background-color: transparent !important;
}

.navbar-dark .navbar-nav .nav-link {
	color: rgb(255 255 255);
}

.navbar-expand-lg .navbar-nav .nav-link {
	padding-left: 1.4rem;
	padding-right: 1.4rem;
}
`;

const StyledBrand = styled(Navbar.Brand)`
    margin: 0.5rem;
	img{
		width:3rem;
		height:3rem;
	}
`

const StyledHr = styled.hr`
	position: absolute;
	transition: width 0.2s ease;
	width:0%;
	float:left;
	border-style: none;
	background: var(--accent-primary);
	height: 4px;
	margin:1px;
`

const StyledContainer = styled.a`
	position:relative;
	&:hover ${StyledHr} {
		width:100%;
		float:right;
	}
`

const NavComponents = () => {
	return (
		<NavContainer>
			<Navbar collapseOnSelect expand="lg" bg="light" variant="dark">
				<StyledBrand href="#home" className="ml-3">
					<img src={logo} alt="logo"/>
				</StyledBrand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="ml-auto mr-3">

						<Nav.Link href="#home">
							<StyledContainer>
								Home
								<StyledHr />
							</StyledContainer>
						</Nav.Link>

						<Nav.Link href="#about">
							<StyledContainer>
								About
								<StyledHr />
							</StyledContainer>
						</Nav.Link>

						<Nav.Link href="#timeline">
							<StyledContainer>
								Timeline
								<StyledHr />
							</StyledContainer>
						</Nav.Link>

						<Nav.Link href="#prizes">
							<StyledContainer>
								Prizes
								<StyledHr />
							</StyledContainer>
						</Nav.Link>

						<Nav.Link href="#sponsors">
							<StyledContainer>
								Sponsors
								<StyledHr />
							</StyledContainer>
						</Nav.Link>

						<Nav.Link href="#team">
							<StyledContainer>
								Team
								<StyledHr />
							</StyledContainer>
						</Nav.Link>

						<Nav.Link href="#contact">
							<StyledContainer>
								Contact
								<StyledHr />
							</StyledContainer>
						</Nav.Link>

					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</NavContainer>
	)
}

export default NavComponents
