import React from 'react';
import styled from "styled-components";
import HeroHeading from '../../elements/HeroHeading';

const StyledRules = styled.section`
	min-height: 100vh;
	text-align: center;
	color:white;
`
const StyledCenter = styled.div`
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
`

const StyledContainer = styled.div`
	padding:2rem;
	margin: 1rem auto;
	margin-top: 0;
	@media only screen  and (min-width : 1224px) {
		max-width: 40vw;
	}
`

const Image = styled.img`
	border-radius:5px;
    width: 100%;
`

const FlexBreak = styled.div`
	flex-basis: 100%;
	height: 0;
	margin: 0;
	padding: 0;
`

const RulesLayout = () => {
	return (
		<StyledRules id="about">
			<StyledCenter>
				<HeroHeading heading={"About"} />
				<FlexBreak />
				<StyledContainer>
					<Image src="/photos/thumbnail.png" alt="Placeholder" />
				</StyledContainer>
				<StyledContainer>
				Renaissance — a namesake to the period of culture, creativity, and art — is the International hackathon presented by Randomize ();, where the participants from ALL OVER THE WORLD will get to innovate and create with the goal to deploy the projects that they build.
				<br /> <br />
				A chance to suggest the most innovative solutions to the given problem statements in front of the world to showcase their problem solving and inventive skills. For 42 hours the participants will learn the values of teamwork and will have to display their collaborative efforts to win this hackathon which is divided into 3 rounds.
				Each round will not only test their different skill-sets but will help them grow during the process as well.
				<br /> <br />
				It is one of the biggest student-led hackathons in Rajasthan, the culmination of all the previous events leading up to this — the FINAL DESTINATION of our Road to Hackathon.
				</StyledContainer>
			</StyledCenter>
		</StyledRules>
	)
}

export default RulesLayout
