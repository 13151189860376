import React from 'react'
import styled from "styled-components"
import HeroHeading from '../../elements/HeroHeading'

const StyledTimeline = styled.section`
	min-height: 100vh;
	text-align: center;
	color:white;

	.card{
		background-color: transparent !important;
		border: none !important;
	}

	.timeline {
		border-left: 3px solid var(--accent-primary);
		border-bottom-right-radius: 4px;
		border-top-right-radius: 4px;
		margin: 0 auto 2rem;
		letter-spacing: 0.2px;
		position: relative;
		line-height: 1.4em;
		font-size: 1.03em;
		padding: 50px;
		list-style: none;
		text-align: left;
		max-width: 40%;
	}

	@media (max-width: 767px) {
		.timeline {
			max-width: 98%;
			padding: 25px;
		}
	}

	@media (min-width: 767px) {
		.timeline{
			margin-left: 44%;
		}
	}

	.timeline h1 {
		font-weight: 300;
		font-size: 1.4em;
	}

	.timeline h2,
	.timeline h3 {
		font-weight: 600;
		font-size: 1.5rem;
		margin-bottom: 10px;
	}

	.timeline .event {
		border-bottom: 1px dashed #e8ebf1;
		padding-bottom: 25px;
		margin-bottom: 25px;
		position: relative;
	}

	@media (max-width: 767px) {
		.timeline .event {
			padding-top: 30px;
		}
	}

	.timeline .event:last-of-type {
		padding-bottom: 0;
		margin-bottom: 0;
		border: none;
	}

	.timeline .event:before,
	.timeline .event:after {
		position: absolute;
		display: block;
		top: 0;
	}

	.timeline .event:before {
		left: -290px;
		content: attr(data-date);
		text-align: right;
		font-weight: 100;
		font-size: 1.5rem;
		min-width: 120px;
	}

	@media (max-width: 767px) {
		.timeline .event:before {
			left: 0px;
			text-align: left;
		}
	}

	.timeline .event:after {
		-webkit-box-shadow: 0 0 0 3px #727cf5;
		box-shadow: 0 0 0 3px #727cf5;
		left: -55.8px;
		background: #fff;
		border-radius: 50%;
		height: 9px;
		width: 9px;
		content: "";
		top: 5px;
	}

	@media (max-width: 767px) {
		.timeline .event:after {
			left: -31.8px;
		}
	}

	.rtl .timeline {
		border-left: 0;
		text-align: right;
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		border-bottom-left-radius: 4px;
		border-top-left-radius: 4px;
		border-right: 3px solid #727cf5;
	}

	.rtl .timeline .event::before {
		left: 0;
		right: -170px;
	}

	.rtl .timeline .event::after {
		left: 0;
		right: -55.8px;
	}
`

const FlexBreak = styled.div`
	flex-basis: 100%;
	height: 0;
	margin: 0;
	padding: 0;
`

const StyledCenter = styled.div`
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
`

const index = () => {
	return (
		<StyledTimeline id="timeline">
			<StyledCenter>
					<HeroHeading heading = {"Timeline"} />
				<FlexBreak />
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="card">
								<div className="card-body">
									<div id="content">
										<ul className="timeline">
											<li className="event" data-date="15th April">
												<h3>Registrations Start</h3>
												<p>Application and Idea Submission Period Starts</p>
											</li>

											<li className="event" data-date="20th April 23:59">
												<h3>Registration closes</h3>
												<p>Application period ends on devfolio</p>
											</li>

											<li className="event" data-date="21st April 18:00">
												<h3>Idea Submission Period Ends</h3>
												<p>Teams should have submitted their ideas by now</p>
											</li>

											<li className="event" data-date="22nd April 00:00">
												<h3>Building Period starts</h3>
												<p>Teams can now start working on their ideas</p>
											</li>
											
											<li className="event" data-date="24th April 00:00">
												<h3>Final Submission</h3>
												<p>Final Submission on Devfolio</p>
											</li>

											<li className="event" data-date="24th April 15:00">
												<h3>Announcement of Top 10 Teams</h3>
												<p>The top 10 teams will build for final evaluation</p>
											</li>


											<li className="event" data-date="24th April 17:00-20:00">
												<h3>Final Judging Round</h3>
												<p>The top 10 teams will pitch to the judges</p>
											</li>

											
											<li className="event" data-date="24th April 22:00">
												<h3>Winners Announcement</h3>
												<p>Winners are announced</p>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</StyledCenter>
		</StyledTimeline>
	)
}

export default index
